import { UserValues } from '../components/molecules/Form/UserPreviewForm';
import {
  Configuration,
  DEPENDENCY_TYPE_GROUP,
  OptionList,
  OPTION_LIST_TYPE_COLOR,
  WEBPART_COLOR_NUMBER,
  WEBPART_CS_BARCODE,
} from '../store/configurator/types';
import {
  CartOrder,
  CartOrderPropertyConfiguration,
} from '../store/order/types';
import {
  getOptionLists,
  getOptionListType,
  getVisibleOptionLists,
  getDependentOptionLists,
} from './optionList';

export const getOrderData = (
  id: number,
  values: UserValues,
  configurations: Configuration[],
  remark?: string,
  totalPriceDiscount?: number,
): CartOrder => {
  const { optionLists } = getOptionLists(configurations, id);
  const visibleOptionLists: OptionList[] = getVisibleOptionLists(optionLists);

  const properties: CartOrderPropertyConfiguration[] =
    visibleOptionLists.reduce((a: CartOrderPropertyConfiguration[], c) => {
      const selectedOption = c.options.find((option) => option.selected);
      const csBarcode =
        selectedOption!.textValues.find(
          (textValue) => textValue.name === WEBPART_CS_BARCODE,
        )?.text || '';

      if (selectedOption) {
        const type = getOptionListType(optionLists, c.optionListId);
        const property: CartOrderPropertyConfiguration = {
          id: c.lineNr,
          name: c.name,
          value: selectedOption!.lineNr,
          displayValue: selectedOption!.name,
          type: 'optionlist',
          price: selectedOption!.price,
          data: {
            optionListId: c.optionListId,
            optionId: selectedOption!.lineNr,
            name: selectedOption!.name,
            csBarcode,
          },
        };

        const dependency = c.dependencies.find((dependency) => {
          return dependency.type === DEPENDENCY_TYPE_GROUP;
        });

        if (
          dependency &&
          getOptionListType(
            optionLists,
            dependency.dependentUponOptionListId,
          ) === OPTION_LIST_TYPE_COLOR
        ) {
          return a;
        }

        if (type === OPTION_LIST_TYPE_COLOR) {
          const dependecies = getDependentOptionLists(
            optionLists,
            c.lineNr,
          );

          // If the color option list has dependecies. its 'primair/secundair/logo'.
          if (dependecies.length > 0) {
            const dependency = dependecies[0];
            const option = dependency.options.find((option) => option.selected);
            const code = option?.textValues.find(
              (textValue) => textValue.name === WEBPART_COLOR_NUMBER,
            );

            const propertyWithDependecy = {
              ...property,
              data: {
                ...property.data,
                dependency,
                friendlyName: code
                  ? `${dependency.name} (${code.text})`
                  : dependency.name,
                name: code ? code.text : '',
                dependencyOptionListOptionLineNr: option?.lineNr,
                dependencyPropertyLineNr: dependency.lineNr,
                dependencyOptionListId: option?.optionListId
              },
            };

            // @ts-ignore
            return [...a, propertyWithDependecy];
          } else {
            return a;
          }
        } else {
          return [...a, property];
        }
      }

      return a;
    }, []);

  const data: CartOrder = {
    firstName: values.firstName,
    lastName: values.insertion
      ? `${values.insertion || ''} ${values.lastName || ''}`
      : values.lastName,
    zipcode: values.zipCode,
    housenumberAndAddition: `${values.houseNumber || ''}${values.houseNumberAddition || ''
      }`,
    street: values.street,
    city: values.place,
    phoneNumber: values.number,
    email: values.email,
    newsletter: false,
    conditions: true,
    countryId: values.country,
    products: [
      {
        id,
        quantity: 1,
        propertyConfiguration: properties,
      },
    ],
    paymentMethodId: 1333,
    remark,
    totalPriceDiscount,
    deliveryDate: values.expectedDeliveryDate,
  };

  return data;
};
