import React, { FC } from 'react';
import Button from '../atoms/Button';
import styled from 'styled-components';
import { UserValues } from '../molecules/Form/UserPreviewForm';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Order } from '../../store/editOrder/types';
import { getOrderData } from '../../utils/order';
import { Configuration } from '../../store/configurator/types';
import { editOrder } from '../../store/editOrder/actions';

const Wrapper = styled.div`
  @media (max-width: 800px) {
    order: 2;
    margin-bottom: 1rem;
  }
`;

interface SaveOrderProps {
    productId: number;
}

const SaveOrder: FC<SaveOrderProps> = ({ productId }) => {
    const dispatch = useDispatch();
    const order = useSelector<RootState, Order | undefined>(
        ({ editOrder }) => editOrder.order
    );

    const configurations = useSelector<RootState, Configuration[]>(
        ({ configurator }) => configurator.configurations,
    );
    const orderInvoice = order?.invoice;

    const handlePlaceOrder = async () => {
        if (order == null || orderInvoice == null) {
            return;
        }

        const userData: UserValues = {
            country: orderInvoice.countryId,
            email: orderInvoice.user.email,
            firstName: orderInvoice.user.firstName,
            insertion: orderInvoice.user.insertion ?? '',
            lastName: orderInvoice.user.lastName,
            zipCode: orderInvoice.zipCode,
            street: orderInvoice.street,
            houseNumber: orderInvoice.houseNumber,
            houseNumberAddition: orderInvoice.houseNumberAddition,
            number: orderInvoice.user.phone,
            place: orderInvoice.city,
            shareDesign: false,
            shareDesignStore: false,
            sellerCode: ''
        }

        const data = getOrderData(productId, userData, configurations, order.description);

        dispatch(editOrder(order.id, data));
    };

    return (
        <Wrapper>
            <Button primary onClick={handlePlaceOrder}>
                Order opslaan
            </Button>
        </Wrapper>
    );
};

export default SaveOrder;
