import React, { FC } from 'react';
import Bike from '../organisms/Bike';
import Configuration from '../organisms/Configuration';
import NavigationSteps from '../organisms/NavigationSteps';
import EditOrderFooter from '../organisms/EditOrderFooter';
import Logo from '../atoms/Logo';
import Header from '../organisms/Header';
import { Container, Wrapper, BikeWrapper } from './Configurator';

interface EditOrderConfiguratorProps {
  productId: number;
}

// Weird marco component. Just a copy of another component, with 1 thing different.
const EditOrderConfigurator: FC<EditOrderConfiguratorProps> = ({
  productId,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
      }}
    >
      <div>
        <Container>
          <Wrapper>
            <Header>
              <Logo />
            </Header>
            <BikeWrapper>
              <Bike productId={productId} />
            </BikeWrapper>
          </Wrapper>
          <NavigationSteps productId={productId} hideProducts />
        </Container>
        <Configuration productId={productId} hideProducts />
      </div>
      <EditOrderFooter productId={productId} />
    </div>
  );
};

export default EditOrderConfigurator;
